<template>
  <main>
    <Work v-for="(work, index) in works" :work="work" :key="index" />
  </main>
</template>

<script>
import Work from '../components/Work.vue'

export default {
  components: {
    Work,
  },

  data() {
    return {
      works: [],
      requested: 0,
      loading: false,
    }
  },

  created() {
    this.getWorks()
  },

  mounted() {
    window.addEventListener('scroll', this.scrollHandler, false)
  },

  beforeDestroy() {
    window.removeEventListener('scroll', this.scrollHandler, false)
  },

  methods: {
    async getWorks(offset = 0, limit = 5) {
      this.loading = true
      this.requested += limit
      const works = await this.$api.getItems('works', { offset, limit, fields: ['id', 'dimensions', 'materials', 'title', 'year', 'images.*.*'] })
      this.works.push(...works.data.map((work) => {
        const d = work
        d.images = work.images.map((image) => image.directus_files_id)
        return d
      }))
      this.$nextTick(() => {
        this.loading = false
      })
    },

    scrollHandler() {
      if (this.loading) return
      const docHeight = document.body.offsetHeight
      const scrollHeight = window.innerHeight + window.pageYOffset
      if (scrollHeight >= docHeight - window.innerHeight) {
        this.getWorks(this.requested)
      }
    },
  },
}
</script>

<style>

</style>
