<template>
  <div
    class="banner"
    ref="banner"
    :class="{
      inverted,
      'tint': invertOnHover
    }"
  >
    <h2
      ref="reference"
      class="reference"
      :style="{
        marginRight: `${marginRight}px`
      }"
    >
      {{ text }}
    </h2>
    <div class="runners" ref="runners">
      <h2
        v-for="(instance, index) in instancesArr"
        :key="index"
        :style="{
          marginRight: `${marginRight}px`,
          transform,
        }"
      >
        {{ text }}
      </h2>
    </div>
  </div>
</template>

<script>
import Hammer from 'hammerjs'
import gsap from 'gsap'
import FontFaceObserver from 'fontfaceobserver'

export default {
  name: 'CustomMarquee',
  props: {
    text: {
      type: String,
      required: true,
    },
    invertOnHover: {
      type: Boolean,
      required: false,
      default: false,
    },
    marginRight: {
      type: Number,
      required: false,
      default: 200,
    },
    inverted: {
      type: Boolean,
      required: false,
      default: false,
    },
    idleVelocity: {
      type: Number,
      required: false,
      default: -0.7,
    },
    initialPosition: {
      type: Number,
      required: false,
      default: 10,
    },
    idleAfter: {
      type: Number,
      required: false,
      default: 2,
    },
  },

  data() {
    return {
      visible: false,

      position: this.initialPosition,
      velocity: 0,
      velocityTween: undefined,

      instances: 5,
      referenceWidth: 0,

      pan: {
        hammertime: undefined,
        panning: false,
        lastEvent: undefined,
        acc: 0,
      },

      observer: undefined,

      viewport: {
        x: window.innerWidth,
        y: window.innerHeight,
      },
    }
  },

  watch: {
    text() {
      this.$nextTick(this.setInstances)
    },

    'viewport.x': {
      handler() {
        this.setInstances()
      },
    },
  },

  async mounted() {
    const font = new FontFaceObserver('cirruscumulusregular')
    await font.load()

    this.$nextTick(this.setInstances)

    this.initializeTouchEvents()
    this.initializeObserver()

    window.addEventListener('resize', this.resizeHandler, false)
  },

  computed: {
    instancesArr() {
      return new Array(this.instances).fill(0)
    },

    transform() {
      const x = this.position % (this.referenceWidth + this.marginRight)
      return `translateX(${x}px)`
    },
  },

  beforeDestroy() {
    this.visible = false

    gsap.killTweensOf(this)

    window.removeEventListener('resize', this.resizeHandler, false)

    this.hammertime.off('panstart', this.panStart)
    this.hammertime.off('panmove', this.panMove)
    this.hammertime.off('panend', this.panEnd)
    this.hammertime.off('pancancel', this.panEnd)

    this.observer.unobserve(this.$refs.banner)
  },

  methods: {
    setViewportSize() {
      this.viewport.x = window.innerWidth
      this.viewport.y = window.innerHeight
    },

    initializeTouchEvents() {
      this.hammertime = new Hammer(this.$el, {
        touchActions: 'pan-x',
      })
      this.hammertime.on('panstart', this.panStart)
      this.hammertime.on('panmove', this.panMove)
      this.hammertime.on('panend pancancel', this.panEnd)
    },

    initializeObserver() {
      if (window.IntersectionObserver) {
        this.observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                this.viewportEnter()
              } else {
                this.viewportLeave()
              }
            })
          },
          {
            rootMargin: '0px 0px 0px 0px',
          },
        )

        this.observer.observe(this.$refs.banner)
      } else {
        this.viewportEnter()
      }
    },

    panStart(e) {
      this.pan.panning = true
      this.pan.lastEvent = e
      gsap.killTweensOf(this)
      gsap.set(this, {
        velocity: 0,
      })
    },

    panMove(e) {
      if (!this.pan.lastEvent) {
        this.pan.lastEvent = e
        return
      }

      const lastDeltaX = this.pan.lastEvent.deltaX
      const frameDeltaX = e.deltaX - lastDeltaX
      this.pan.acc += frameDeltaX
      this.pan.lastEvent = e
    },

    panEnd() {
      this.pan.panning = false
      gsap.to(this, {
        velocity: this.idleVelocity,
      })
    },

    resizeHandler() {
      this.setViewportSize()
    },

    viewportEnter() {
      this.visible = true
      this.position = this.initialPosition
      gsap.set(this, {
        velocity: 0,
      })
      gsap.to(this, {
        velocity: this.idleVelocity,
        duration: 1,
        delay: this.idleAfter,
      })
      this.render()
    },

    viewportLeave() {
      this.visible = false
      this.position = this.initialPosition
      gsap.killTweensOf(this)
      gsap.set(this, {
        velocity: 0,
      })
    },

    render() {
      if (!this.visible) return

      if (this.pan.panning) {
        gsap.set(this, {
          velocity: this.pan.acc,
        })
        this.pan.acc = 0
      }

      this.position += this.velocity
      if (this.position > 0) this.position -= this.referenceWidth + this.marginRight

      window.requestAnimationFrame(this.render)
    },

    setInstances() {
      this.referenceWidth = this.$refs.reference.getBoundingClientRect().width
      this.instances = Math.ceil(this.viewport.x / this.referenceWidth) + 1
      this.position = this.initialPosition
    },
  },
}
</script>

<style lang="scss" scoped>
.banner {
  border-width: var(--marquee-border-width);
  border-color: black;
  border-style: solid;
  border-left-width: 0 !important;
  border-right-width: 0 !important;
  overflow: hidden;
  position: relative;
  transition: background 1s, color 1s;
  background: white;
  user-select: none;
  margin-bottom: calc(var(--marquee-border-width) * -1);

  &.inverted {
    background: black;
    color: white;
  }

  @media not all and (pointer: coarse) {
    &.tint:hover {
      background: black;
      color: white;
      cursor: pointer;
    }

    &.inverted.tint:hover {
      background: white;
      color: black;
    }
  }

  h2 {
    display: inline-block;
    white-space: nowrap;
    margin-top: var(--marquee-h2-margin-top);

    &.reference {
      opacity: 0;
    }
  }

  .runners {
    position: absolute;
    left: 0;
    top: 0;
    width: auto;
    display: flex;
    flex-direction: row;

    h2 {
      flex: 0;
    }
  }
}
</style>
