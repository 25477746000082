<template>
  <div
    class="container"
    :style="{
      paddingBottom,
    }"
  >
    <img ref="image" :src="src" alt="">
  </div>
</template>

<script>
import imagesLoaded from 'imagesloaded'
import gsap from 'gsap'

export default {
  props: {
    width: {
      type: Number,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
    src: {
      type: String,
      required: true,
    },
  },

  computed: {
    paddingBottom() {
      return `${(this.height / this.width) * 100}%`
    },
  },

  mounted() {
    imagesLoaded(this.$refs.image, () => {
      gsap.to(this.$refs.image, {
        opacity: 1,
        duration: 0.3,
      })
    })
  },
}
</script>

<style lang="scss" scoped>
.container {
  position: relative;
  height: 0;

  img {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    opacity: 0;
  }
}

</style>
