import Vue from 'vue'
import router from './router/router'
import api from './mixins/api'
import App from './App.vue'
import events from './events'

Vue.config.productionTip = false
Vue.prototype.$api = api

Vue.use(router)

Vue.prototype.$eventHub = new Vue()
Vue.prototype.$events = events

new Vue({
  router,
  render: (h) => h(App),
}).$mount('#app')
