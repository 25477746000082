<template>
  <div>
    <CustomMarquee
      :rolling="true"
      :text="navMarqueeText"
      :invertOnHover="true"
      :pauseOnHover="true"
      :log="true"
      :mouseRolling="false"
      :idleAfter="idleAfter"
      @click.native="$router.push(navTarget)"
    ></CustomMarquee>
    <Chinabox></Chinabox>
    <router-view></router-view>
  </div>
</template>

<script>
import Chinabox from './components/Chinabox.vue'
import CustomMarquee from './components/CustomMarquee.vue'
import logCredits from './js/credits'

export default {
  name: 'App',
  components: {
    Chinabox,
    CustomMarquee,
  },

  mounted() {
    logCredits()
  },

  computed: {
    idleAfter() {
      if (this.$route.name === 'about') return 0
      return 2
    },

    navTarget() {
      if (this.$route.name === 'home') return { name: 'about' }
      return { name: 'home' }
    },

    navMarqueeText() {
      if (this.$route.name === 'home') return 'Mathis Pauer – +49 163 3013924 – mathisbauer@gmx.de – Impressum'
      return 'Back'
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
