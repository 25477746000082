<template>
  <div class="work">
    <h2 class="spacer">&nbsp;</h2>
    <div class="images" ref="images">
      <ResponsiveImage
        v-for="(image, index) in images"
        :key="index"
        :src="image.url"
        :width="image.width"
        :height="image.height"
      ></ResponsiveImage>
    </div>

    <div class="sticky-container">
      <CustomMarquee
        class="banner"
        :text="bannerText"
        :rolling="true"
        :pauseOnHover="true"
      ></CustomMarquee>
    </div>
  </div>
</template>

<script>
import gsap from 'gsap'
import ResponsiveImage from './ResponsiveImage.vue'
import CustomMarquee from './CustomMarquee.vue'

export default {
  components: {
    CustomMarquee,
    ResponsiveImage,
  },

  props: {
    work: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      collapsed: false,
      images: this.work.images.map((image) => ({
        ...image,
        url: image.data.thumbnails.find((thumb) => thumb.key === 'thumb-large').url,
      })),
    }
  },

  mounted() {
    this.$eventHub.$on(this.$events.TOGGLE_COLLAPSE, this.toggleCollapse)
  },

  beforeDestroy() {
    this.$eventHub.$off(this.$events.TOGGLE_COLLAPSE, this.toggleCollapse)
  },

  computed: {
    bannerText() {
      return `${this.work.title}, ${this.work.year}, ${this.work.materials}, ${this.work.dimensions}`
    },
  },

  methods: {
    toggleCollapse() {
      gsap.to(this.$refs.images, {
        height: this.collapsed ? 'auto' : 0,
        duration: 0.4,
      })
      this.collapsed = !this.collapsed
    },
  },
}
</script>

<style lang="scss" scoped>
.work {
  width: 100%;
  position: relative;

  .sticky-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;

    .banner {
      position:-webkit-sticky;
      position: sticky;
      top: 0;
      pointer-events: all;
    }
  }

  h2.spacer {
    padding-top: var(--marquee-h2-margin-top);
    border-width: var(--marquee-border-width);
    border-style: solid;
    border-color: transparent;
  }

  .images {
    overflow: hidden;
  }

  img {
    display: block;
    width: 100%;
  }
}
</style>
