<template>
  <main>
    <p v-if="about" v-html="about.data[0].content">
    </p>
  </main>
</template>

<script>
export default {
  data() {
    return {
      about: undefined,
    }
  },
  mounted() {
    this.getAboutContent()
  },
  methods: {
    async getAboutContent() {
      this.about = await this.$api.getItems('about')
    },
  },
}
</script>

<style lang="scss" scoped>

main {
  padding: 4vw 4vw;

  p {
    margin: 0;
    white-space: pre-line;
  }
}

</style>
