import DirectusSDK from '@directus/sdk-js'

const api = new DirectusSDK({
  url: 'https://cms.mathispauer.com/',
  token: 'rX3qhtwu7xDJy4btbkYwDET6QDkT6Kng',
  project: '_',
  storage: window.localStorage,
})

export default api
