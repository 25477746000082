<template>
    <img
      ref="image"
      v-if="initialized"
      @click="clickHandler"
      src="../assets/images/chinabox.png"
      alt=""
      :style="{
        width: `${width}px`,
      }"
    >
</template>

<script>
import gsap from 'gsap'

export default {
  props: {
    speedMultiplier: {
      type: Number,
      required: false,
      default: () => 0.01,
    },

    rotationSpeed: {
      type: Number,
      required: false,
      default: 0.5,
    },
  },

  data() {
    return {
      initialized: false,

      width: 130,

      position: {
        x: undefined,
        y: undefined,
      },

      speed: {
        x: undefined,
        y: undefined,
      },

      rotation: Math.random() * 360,
      rotationDir: 1,

      detectCollisions: false,
      onBeforeRender: new Set([]),
    }
  },

  watch: {
    $route: {
      immediate: true,
      handler(newRoute) {
        this.$nextTick(() => {
          if (newRoute.name === 'about') {
            if (!this.initialized) {
              this.initialize()
              this.render()
            } else if (this.isInViewport) {
              this.detectCollisions = true
            } else {
              this.reset()
            }
          } else {
            this.detectCollisions = false
          }
        })
      },
    },
  },

  computed: {
    speedMultiplierWithViewportWidth() {
      return (this.speedMultiplier / window.innerWidth) * 200
    },

    positionInPixel() {
      return {
        x: this.position.x * (window.innerWidth - this.width),
        y: this.position.y * (window.innerHeight - this.width),
      }
    },

    isInViewport() {
      return this.position.x < 1
        && this.position.x > 0
        && this.position.y < 1
        && this.position.y > 0
    },

    collision() {
      return {
        x: this.position.x < 0 || this.position.x > 1,
        y: this.position.y < 0 || this.position.y > 1,
      }
    },
  },

  methods: {
    reset() {
      this.detectCollisions = false
      this.positionOutsideOfViewport()
      this.onBeforeRender.add(this.checkForInitialVisibility)
    },

    positionOutsideOfViewport() {
      this.position.x = this.randomBool() ? 1.2 : -0.2
      this.position.y = this.randomBool() ? 1.2 : -0.2

      this.speed.x = this.position.x > 0.5
        ? Math.max(0.5, Math.random()) * this.speedMultiplierWithViewportWidth * -1
        : Math.max(0.5, Math.random()) * this.speedMultiplierWithViewportWidth

      this.speed.y = this.position.y > 0.5
        ? Math.max(0.5, Math.random()) * this.speedMultiplierWithViewportWidth * -1
        : Math.max(0.5, Math.random()) * this.speedMultiplierWithViewportWidth
    },

    initialize() {
      this.detectCollisions = false
      this.positionOutsideOfViewport()
      this.onBeforeRender.add(this.checkForInitialVisibility)
      this.initialized = true
    },

    checkForInitialVisibility() {
      if (this.isInViewport) {
        this.onBeforeRender.delete(this.checkForInitialVisibility)
        if (this.$route.name === 'about') this.detectCollisions = true
      }
    },

    randomSign() {
      return Math.random() <= 0.5 ? -1 : 1
    },

    randomBool() {
      return this.randomSign() !== -1
    },

    clickHandler() {
      window.open('https://www.instagram.com/mathispauer/', '_blank')
    },

    collisionDetection() {
      if (this.detectCollisions) {
        if (this.collision.x) {
          this.speed.x *= -1
          this.rotationDir *= -1
        }
        if (this.collision.y) {
          this.rotationDir *= -1
          this.speed.y *= -1
        }
      }
    },

    render() {
      this.onBeforeRender.forEach((c) => c())

      this.collisionDetection()

      this.position.x += this.speed.x
      this.position.y += this.speed.y
      this.rotation += this.rotationSpeed * this.rotationDir

      if (this.$refs.image) {
        gsap.set(this.$refs.image, {
          x: this.positionInPixel.x,
          y: this.positionInPixel.y,
          rotateZ: this.rotation,
        })
      }

      window.requestAnimationFrame(this.render)
    },
  },
}
</script>

<style lang="scss" scoped>
img {
  position: fixed;
  top: 0;
  left: 0;
  will-change: transform;
  cursor: pointer;
  z-index: 1;
  display: block;
}
</style>
