const str = `
██████  ██████  ██████  ███████     ██████  ██    ██       
██      ██    ██ ██   ██ ██          ██   ██  ██  ██        
██      ██    ██ ██   ██ █████       ██████    ████         
██      ██    ██ ██   ██ ██          ██   ██    ██          
 ██████  ██████  ██████  ███████     ██████     ██          
                                                            
                                                            
██      ███████  ██████  ██████  ██ ███████  ██████ ██   ██ 
██      ██      ██       ██   ██ ██ ██      ██      ██   ██ 
██      █████   ██   ███ ██████  ██ ███████ ██      ███████ 
██      ██      ██    ██ ██   ██ ██      ██ ██      ██   ██ 
███████ ███████  ██████  ██   ██ ██ ███████  ██████ ██   ██                                                          

Grischa Erbe
legrisch.com
hello@legrisch.com

`

const logCredits = () => {
  console.log(str)
}

export default logCredits
